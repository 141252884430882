import {
    BarChartOutlined,
    BarcodeOutlined,
    BookOutlined,
    BuildOutlined,
    ContainerOutlined,
    FilePdfOutlined,
    LaptopOutlined,
    LogoutOutlined,
    ReconciliationOutlined,
    ShoppingOutlined,
    SnippetsOutlined,
    ToolOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { Link } from "react-router-dom";

type MenuItemsCustom = {
    label: any;
    icon: JSX.Element;
    link: string;
    children: { label: string; link: string }[];
    className?: string;
};

export const menuNavData = () => {
    const menuItems: MenuItemsCustom[] = [
        {
            label: "User",
            icon: <UserOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "user",
            children: [
                {
                    label: "User List",
                    link: "user-list",
                },
                {
                    label: "User Assignments Schedule",
                    link: "user-assignments-schedule",
                },
                {
                    label: "Users Task List",
                    link: "users-task-list",
                },
            ],
        },
        {
            label: "Shop",
            icon: <ShoppingOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "shops",
            children: [
                {
                    label: "Shop list",
                    link: "shop-list",
                },

                {
                    label: "Matching Seller Category",
                    link: "matching-seller-category",
                },
                {
                    label: "Append/replace XML Data",
                    link: "append-replace-xml-data",
                },
            ],
        },
        {
            label: "Maps",
            icon: <BookOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "maps",
            children: [
                {
                    label: "Main maps",
                    link: "main-map",
                },

                {
                    label: "XML Merged",
                    link: "xml-merged",
                },
            ],
        },
        {
            label: "Reports",
            icon: <BarChartOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "reports",
            children: [
                {
                    label: "Daily Report",
                    link: "daily-report",
                },

                {
                    label: "User Logger",
                    link: "user-logger",
                },
                {
                    label: "Feed health - From to",
                    link: "feed-health-from-to",
                },
                {
                    label: "Prices and products",
                    link: "prices-products-report",
                },
                {
                    label: "Feeds",
                    link: "all-feeds",
                },
                {
                    label: "Feed Checker",
                    link: "feed-checker",
                },
                {
                    label: "Mpn check",
                    link: "mpn-check",
                },
            ],
        },
        {
            label: "Products",
            icon: <SnippetsOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "products",
            children: [
                {
                    label: "Prices Difference",
                    link: "prices-difference",
                },
                {
                    label: "Product name mass editing",
                    link: "product-name-mass-editing",
                },
                {
                    label: "Comments",
                    link: "comments-list",
                },
                {
                    label: "Product Images",
                    link: "product-images",
                },
                {
                    label: "AM Offer - Product",
                    link: "automatched-offer-product",
                },
                {
                    label: "AM Product - Product",
                    link: "automatched-product-product",
                },
                {
                    label: "Edit product mpn",
                    link: "edit-product-mpn",
                },
                {
                    label: "Ean Cleaning",
                    link: "ean-cleaning",
                },
                {
                    label: "Ean Overview",
                    link: "ean-overview",
                },
                {
                    label: "Description and Review",
                    link: "description-review",
                },
                {
                    label: "Description AI",
                    link: "description-ai",
                },
                {
                    label: "Product",
                    link: "product",
                },
            ],
        },
        {
            label: "Brands",
            icon: <BarcodeOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "brands",
            children: [
                {
                    label: "Brands List",
                    link: "brand-list",
                },
                {
                    label: "Cleanup Brands",
                    link: "cleanup-brands",
                },
                {
                    label: "Shop Brands",
                    link: "shop-brands",
                },
            ],
        },
        {
            label: "Category",
            icon: <ContainerOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "category",
            children: [
                {
                    label: "Category List",
                    link: "category-list",
                },
                {
                    label: "Re-categorizing",
                    link: "re-categorizing",
                },
                {
                    label: "Category suffix",
                    link: "category-prefix-suffix",
                },
                {
                    label: "FAQ",
                    link: "frequently-asked-questions",
                },
            ],
        },
        {
            label: "Attributes",
            icon: <ReconciliationOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "attributes",
            children: [
                {
                    label: "Attributes Overview",
                    link: "attributes-overview",
                },
                {
                    label: "Attribute Mapping",
                    link: "attribute-mapping",
                },
                {
                    label: " Attribute value cleaning rules",
                    link: "attribute-mapping-rules",
                },
                {
                    label: "Top product attributes",
                    link: "top-product-attributes",
                },
                {
                    label: "Top Attribute Mapping",
                    link: "top-attribute-mapping",
                },
                {
                    label: "Attribute analytics",
                    link: "attributes-analytics",
                },
            ],
        },
        {
            label: "Tools",
            icon: <ToolOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "tools",
            children: [
                {
                    label: "Word Replacement",
                    link: "word-replacement",
                },
                {
                    label: "Universal Scraper",
                    link: "universal-scraper",
                },
                {
                    label: "Scrapers Overview",
                    link: "scrapers-overview",
                },
            ],
        },

        {
            label: "Shoptok pages",
            icon: <LaptopOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "shoktok-pages",
            children: [],
        },
        {
            label: "Catalogs",
            icon: <FilePdfOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "catalogs",
            children: [
                {
                    label: "Create Catalog",
                    link: "create-catalog",
                },
                {
                    label: "Catalog List",
                    link: "catalog-list",
                },
            ],
        },
        {
            label: "All Products",
            icon: <BuildOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />,
            link: "all-products",
            children: [],
        },
    ];

    const items: MenuProps["items"] = menuItems.map((item) => ({
        label:
            item.children.length > 0 ? (
                <div>
                    {item.icon}
                    {item.label}
                </div>
            ) : (
                <Link to={item.link} className="nav-icon">
                    {item.icon}
                    {item.label}
                </Link>
            ),
        key: item.link,
        children: item.children.length > 0 ? item.children.map((subItem) => ({ label: <Link to={`/${subItem.link}`}>{subItem.label}</Link>, key: subItem.link })) : undefined,
    }));
    return items;
};

type MenuItemsUserCustom = {
    label: any;
    icon: JSX.Element;
    link: string;
    children: { label: string | JSX.Element; link: string }[];
    className?: string;
};

export const menuUserData = (logOut: () => void) => {
    const menuUserItems: MenuItemsUserCustom[] = [
        {
            label: "",
            icon: <UserOutlined style={{ fontSize: "20px" }} />,
            link: "profile",
            children: [
                {
                    label: "Tasks",
                    link: "user-tasks",
                },
                {
                    label: "Edit Profile",
                    link: "edit-user",
                },
                {
                    label: (
                        <div onClick={() => logOut()} style={{ zIndex: 2000 }}>
                            <LogoutOutlined className="logout-icon" /> Logout
                        </div>
                    ),
                    link: "logout",
                },
            ],
        },
    ];

    const itemsUser: MenuProps["items"] = menuUserItems.map((item) => ({
        label:
            item.children.length > 0 || item.link === "logout" ? (
                <div>
                    {item.icon}
                    {item.label}
                </div>
            ) : (
                <Link to={item.link} className="nav-icon" style={{ color: "white" }}>
                    {item.icon}
                    {item.label}
                </Link>
            ),
        key: item.link,
        children:
            item.children.length > 0
                ? item.children.map((subItem) => ({
                      label: subItem.link === "logout" ? subItem.label : <Link to={`/${subItem.link}`}>{subItem.label}</Link>,
                      key: subItem.link,
                  }))
                : undefined,
    }));
    return itemsUser;
};
