import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { CountryType } from "../../Context/type";
import { useAuth } from "../../Context/AuthContext";

interface CountrySelectProps {
    selectCountry: (e: number) => void;
}

const CountrySelect = (props: CountrySelectProps) => {
    const { selectCountry } = props;
    const { currentCountry } = useAuth();
    const [form] = Form.useForm();

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];
    useEffect(() => {
        localStorageCountries && form.setFieldValue("country_select", currentCountry.id);
    }, [form, currentCountry?.id, localStorageCountries]);

    return (
        <Form form={form} initialValues={{ country_select: localStorageCountries ? currentCountry.id : undefined }} style={{ marginTop: "0.5rem" }}>
            <Form.Item name="country_select" style={{ width: "76px" }}>
                <Select onChange={selectCountry} className="country-selector" size="small" suffixIcon={false}>
                    {localStorageCountries?.map((country) => (
                        <Select.Option style={{ textAlign: "center" }} key={country.value} value={country.value}>
                            <div
                                style={{
                                    display: "flex",
                                    //justifyContent: "space-between",
                                    marginTop: "4px",
                                }}
                            >
                                <img width={23} height={15} src={`https://datatok.s3.eu-central-1.amazonaws.com/country-flags/${country.name}_flag.png`} alt="flag" /> {/*       here !!!!!!!! */}
                                <span
                                    style={{
                                        marginLeft: "3px",
                                        position: "relative",
                                        bottom: "4px",
                                    }}
                                >
                                    {country.name.toUpperCase()}
                                </span>
                            </div>
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    );
};

export default CountrySelect;
