import React, { useEffect, useState } from "react";
import { Menu, Button, Typography, Tooltip, Modal } from "antd";
import { capitalizeFirstLetter } from "../../screens/attributes/helpers/checkIsIncludes";
import type { MenuProps } from "antd";
import logoImage from "../../images/logo2.png";
import "./NavBar.css";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import CountrySelect from "./components/CountrySelect";
import { CountryType } from "../Context/type";
import NavInfoBtn from "./components/NavInfoBtn";
import usePathname from "../hooks/pathName";
import axios from "axios";
import FadeIn from "react-fade-in";
import CreateInfoText from "./components/CreateInfoText";
import showMessage from "../MessagesInfo/message";
import { useAuth } from "../Context/AuthContext";
import { menuNavData, menuUserData } from "./utils/routeData";

const { Text } = Typography;

const serverType = process.env.REACT_APP_URL_API === "https://editor.eponuda.com/api" ? "LIVE" : process.env.REACT_APP_URL_API === "https://editorbeta.eponuda.com/api" ? "BETA" : "TEST";

const NavBar = () => {
    const [current, setCurrent] = useState("");
    const path = usePathname();
    const [showInfoText, setShowInfoText] = useState(false);
    const [descriptionInit, setDescriptionInit] = useState("");
    const [editorText, setEditorText] = useState("");
    const [saveInfoLoading, setSaveInfoLoading] = useState(false);

    useEffect(() => {
        const uniUrls = ["product", "universal-scraper", "change-catalog"]; // check change-catalog (there's no route)
        if (uniUrls.includes(path?.split("/")[1])) {
            setCurrent(path?.split("/")[1]);
        } else {
            setCurrent(path.slice(1));
        }
    }, [path]);

    const handleCancel = () => {
        setShowInfoText(false);
        setDescriptionInit("");
        setEditorText("");
    };

    const onClick: MenuProps["onClick"] = (e) => {
        setCurrent(e.key);
    };

    const { isLoggedIn, logOut, loadingData, setCurrentCountry } = useAuth();
    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    const selectCountry = (e: number) => {
        const findCountry = localStorageCountries.find((c) => c.id === e)!;
        localStorage.setItem("currentCountry", JSON.stringify({ id: findCountry.id, name: findCountry.name }));
        setCurrentCountry({ id: findCountry.id, name: findCountry.name });
    };

    const openCreateInfoText = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/intelligence/get-app-info?link=${path === "/" ? "/" : path.substring(1)}`);
            if (data.data?.content) {
                setDescriptionInit(data.data?.content);
            }
            setShowInfoText(true);
        } catch (err) {
            console.log(err);
        }
    };

    const saveInfoPageText = async () => {
        const objToSend = {
            content: editorText,
            link: path === "/" ? "/" : path.substring(1),
        };
        setSaveInfoLoading(true);
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/intelligence/upsert-app-info`, objToSend);
            showMessage(data.message);
            setShowInfoText(false);
            setSaveInfoLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="navbar">
            {!loadingData && (
                <div>
                    <Modal
                        title={`Create info text for page - ${capitalizeFirstLetter(`${path === "/" ? "home" : path.substring(1)}`.replaceAll("-", " "))}`}
                        open={showInfoText}
                        onOk={() => setShowInfoText(true)}
                        onCancel={handleCancel}
                        footer={false}
                        width={1000}
                        style={{ height: "600px!important" }}
                    >
                        <FadeIn delay={300} transitionDuration={800}>
                            <CreateInfoText setEditorText={setEditorText} descriptionInit={descriptionInit} />
                        </FadeIn>
                        <Button type="primary" style={{ marginTop: "0.5rem" }} onClick={saveInfoPageText} loading={saveInfoLoading}>
                            Save description
                        </Button>
                    </Modal>
                    <div style={{ position: "absolute", left: 15 }}>
                        <Link to="/" onClick={() => setCurrent("/")}>
                            <img src={logoImage} alt="eponuda-logo" height={46} />
                        </Link>
                        <Text
                            id="text-info-navbar"
                            style={{
                                color: serverType === "LIVE" ? "green" : serverType === "BETA" ? "#ff3838" : "#ff3838",
                            }}
                            onClick={undefined}
                        >
                            Test
                        </Text>
                    </div>
                    {isLoggedIn && <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" theme="dark" items={menuNavData()} className="custum-nav-menu" />}

                    <div className="menu-nav-user">
                        {isLoggedIn && (
                            <Tooltip placement="bottom" title={"Create page info"} color="#113f67">
                                <EditOutlined className="info-btn" onClick={openCreateInfoText} />
                            </Tooltip>
                        )}
                        {isLoggedIn && <NavInfoBtn path={path} />}
                        {isLoggedIn && <CountrySelect selectCountry={selectCountry} />}

                        {isLoggedIn ? (
                            <Menu className="menu-user" onClick={onClick} style={{ width: 56 }} selectedKeys={[current]} mode="horizontal" theme="dark" items={menuUserData(logOut)} />
                        ) : (
                            <Link to="/login" type="primary" className="logout-btn">
                                Loign
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavBar;
